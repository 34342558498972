import React from "react";
import { PortfolioLayoutProvider } from "./src/context/index";
import "./src/css/fonts.css";
import "./src/css/video.css";
import "./src/css/index.css";
import "./node_modules/video-react/dist/video-react.css";

export const wrapRootElement = ({ element }) => (
  <PortfolioLayoutProvider>{element}</PortfolioLayoutProvider>
);
