import React, { useState } from "react";

const PortfolioLayoutContext = React.createContext(true);

export const PortfolioLayoutProvider = ({ children }) => {
  const [portfolioLayout, setPortfolioLayout] = useState(true);

  return (
    <PortfolioLayoutContext.Provider
      value={[portfolioLayout, setPortfolioLayout]}
    >
      {children}
    </PortfolioLayoutContext.Provider>
  );
};


export default PortfolioLayoutContext;
